module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VH764TMGQS"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/pc123-gatsby/pc123-gatsby/node_modules/gatsby-transformer-remark","id":"ba9e1b1a-0861-5271-8cea-964bbb5490f7","name":"gatsby-transformer-remark","version":"5.25.1","modulePath":"/home/runner/work/pc123-gatsby/pc123-gatsby/node_modules/gatsby-transformer-remark/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode","unstable_shouldOnCreateNode","createSchemaCustomization","setFieldsOnGraphQLNodeType","pluginOptionsSchema"],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/pc123-gatsby/pc123-gatsby","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pc123.sk - kamerové systémy a alarmy","short_name":"pc123","description":"pc123 zabezpečí vašu domácnosť a vašu firmu.","lang":"sk","start_url":"/","background_color":"#C80000","theme_color":"#880000","display":"minimal-ui","icon":"src/images/pc123-favicon.png","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":false,"cache_busting_mode":"none","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/kamerove-systemy","/alarmy","/videovratniky","/terminaly","/referencie","/pocitacove-siete"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
