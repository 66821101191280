// export const onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//         `This application has been updated. ` +
//         `Reload to display the latest version?`
//     );
//     if (answer === true) {
//         window.location.reload();
//     }
// };

exports.onServiceWorkerUpdateReady = () => {
    const shouldReload = window.confirm(
        'This website has been updated. Reload to display the latest version?'
    );
    if (shouldReload) {
        window.location.href = window.location.href.replace(/#.*$/, '');
    }
};
exports.onRouteUpdate = async () => {
    if (!navigator) {
        console.log('Navigator is not defined, skipping service worker registration...');
        return;
    }
    if (!navigator.serviceWorker) {
        console.log('Service worker is not supported, skipping registration...');
        return;
    }
    try {
        const registration = await navigator.serviceWorker.register('/sw.js');
        await registration.update();
    } catch (error) {
        console.error('Service worker registration failed', error);
    }
};
