
import merge from "lodash.merge";
import { BREAKPOINTS } from "../constants"

const myColors = {
  myother: "#002a22ff",
  myhighlight: "#6c9077ff",
  myaccentlight: "#e0e0e0",
  myaccentdark: "#484848",
  myborderscolorslight: "#a7a7a7",
  myborderscolorsdark: "#636363",
  mybackground: "#f5f5f5ff",
  myblackgray: "#171717",
  mysecondary: "#c80000ff",
  myseco: "#880000",
  mytext: "#363636",
  mymuted: "##6c757d",
  myprimary: {
    // shades of bright red
    "100": "#f5c99a",
    "200": "#ebb889",
    "300": "#E0A679",
    "400": "#D69568",
    "500": "#CC8358",
    "600": "#C27148",
    "700": "#B86037",
    "800": "#AD4E27",
    "900": "#A33D16",
    "1000": "#992B06",
  },
  redlogo: {
    // shades of red from pc123 logo
    "100": "#FAE6E6",
    "200": "#F4CCCC",
    "300": "#EFB3B3",
    "400": "#E99999",
    "500": "#E48080",
    "600": "#DE6666",
    "700": "#D94D4D",
    "800": "#D33333",
    "900": "#CE1A1A",
    "1000": "#C80000",
  },
};

const borderWidths = {
  px: `1px`,
  "0": `0`,
  "2": `2px`,
  "4": `4px`,
  "8": `8px`,
};


let breakpoints = Object.entries(BREAKPOINTS).map(([key, val]) => {
  return `${val}px`
});
breakpoints.shift()

const baseColors = {
  black: `#000`,
  white: `#fff`,
  gray: [
    // null,
    `#f7fafc`,
    `#edf2f7`,
    `#e2e8f0`,
    `#cbd5e0`,
    `#a0aec0`,
    `#718096`,
    `#4a5568`,
    `#2d3748`,
    `#1a202c`,
  ],
  red: [
    // null,
    `#fff5f5`,
    `#fed7d7`,
    `#feb2b2`,
    `#fc8181`,
    `#f56565`,
    `#e53e3e`,
    `#c53030`,
    `#9b2c2c`,
    `#742a2a`,
  ],
  blue: [
    // null,
    `#ebf8ff`,
    `#bee3f8`,
    `#90cdf4`,
    `#63b3ed`,
    `#4299e1`,
    `#3182ce`,
    `#2b6cb0`,
    `#2c5282`,
    `#2a4365`,
  ],
};

const colors = {
  ...baseColors,

  info: baseColors.blue[4],
  danger: baseColors.red[3],

  text: myColors.mytext,
  background: myColors.mybackground,
  primary: myColors.redlogo[700],
  secondary: myColors.mysecondary,
  accent: myColors.myaccentlight,
  muted: myColors.mymuted,
  primaryhover: baseColors.red[4],
  highlight: myColors.myhighlight,
  heading: baseColors.gray[8],
  divider: baseColors.gray[2],
  textmuted: baseColors.blue[6],
  primaryagainst: myColors.redlogo[400],
  borderscolor: myColors.myborderscolorslight,
  headertext: myColors.redlogo[100],
  headerback: myColors.redlogo[1000],
  footerback: myColors.mytext,
  headerlink: myColors.redlogo[100],
  headerlinkhover: baseColors.gray[1],
  toggleIcon: baseColors.gray[8],

  submenu: "brown",
  modes: {
    dark: {
      text: myColors.mybackground,
      background: myColors.mytext,
      primary: baseColors.gray[4],
      secondary: myColors.myseco,
      accent: myColors.myaccentdark,
      muted: myColors.mymuted,
      primaryhover: baseColors.gray[2],
      highlight: myColors.myhighlight,
      heading: baseColors.gray[3],
      divider: baseColors.gray[8],
      textmuted: baseColors.gray[5],
      primaryagainst: baseColors.gray[2],
      borderscolor: myColors.myborderscolorsdark,
      headertext: baseColors.gray[2],
      headerback: baseColors.gray[8],
      footerback: baseColors.gray[8],
      headerlink: baseColors.gray[4],
      headerlinkhover: baseColors.gray[1],
      submenu: baseColors.gray[8],
      toggleIcon: "yellow",
    },
  },
};

const baseFonts = {
  sans: `-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  serif: `Georgia, Cambria, "Times New Roman", Times, serif`,
  mono: `Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
};

const fonts = {
  ...baseFonts,
  body: baseFonts.sans,
  heading: baseFonts.sans,
  monospace: baseFonts.mono,
};

const fontSizes = [
  `0.875rem`,
  `1rem`,
  `1.25rem`,
  `1.5rem`,
  `1.875rem`,
  `2.25rem`,
  `3rem`,
];

const baseFontWeights = {
  light: `300`,
  normal: `400`,
  medium: `500`,
  bold: `700`,
  black: `900`,
};

const fontWeights = {
  ...baseFontWeights,
  body: baseFontWeights.normal,
  heading: baseFontWeights.light,
};

const letterSpacings = {
  tighter: `-0.05em`,
  tight: `-0.025em`,
  normal: `0`,
  wide: `0.025em`,
  wider: `0.05em`,
  widest: `0.1em`,
};

const baseLineHeights = {
  none: `1`,
  tight: `1.25`,
  snug: `1.375`,
  normal: `1.5`,
  relaxed: `1.625`,
  loose: `2`,
};

const lineHeights = {
  ...baseLineHeights,
  body: baseLineHeights.relaxed,
  heading: baseLineHeights.tight,
};

const radii = {
  none: `0`,
  sm: `0.125rem`,
  default: `0.25rem`,
  lg: `0.5rem`,
  full: `9999px`,
};

const sizes = {
  px: `1px`,
  "0": `0`,
  "1": `0.1rem`,
  "2": `0.25rem`,
  "3": `0.5rem`,
  "4": `0.75rem`,
  "5": `1rem`,
  "6": `1.25rem`,
  "7": `1.5rem`,
  "8": `2rem`,
  "10": `2.5rem`,
  "12": `3rem`,
  "16": `4rem`,
  "20": `5rem`,
  "24": `6rem`,
  "32": `8rem`,
  full: `100%`,
  screenHeight: `100vh`,
  screenWidth: `100vw`,
};

const shadows = {
  default: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  md: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  lg: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  xl: `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
  "2xl": `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
  inner: `inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)`,
  outline: `0 0 0 3px rgba(66, 153, 225, 0.5)`,
  none: `none`,
};

const space = [0, `0.25rem`, `0.5rem`, `1rem`, `2rem`, `4rem`, `8rem`, `16rem`];

const zIndices = {
  auto: `auto`,
  "0": `0`,
  "10": `10`,
  "20": `20`,
  "30": `30`,
  "40": `40`,
  "50": `50`,
};

const headings = {
  fontFamily: `heading`,
  fontWeight: `heading`,
  lineHeight: `heading`,
  m: 0,
  mb: 1,
};
const images = {
  stopercent: {
    maxWidth: "100%",
    padding: 1,
    borderRadius: 2,
  },
  imgSlider: {
    cursor: "pointer",
  },
  imgSliderH100: {
    cursor: "pointer",
    height: "100%",
  },
  slideImgH100: {
    cursor: "pointer",
    height: "fit-content",
  },
};
const buttons = {
  primary: {
    color: "white",
    bg: "primary",
    cursor: "pointer",
    borderColor: "primaryagainst",
    borderStyle: "ridge",
    borderWidth: "2px",
  },
};

const styledLink = {
  textDecoration: "none",
  color: "headerlink",
  display: "inline-block",
  whiteSpace: "nowrap",
  margin: "10px 12px",
  transition: "all 200ms ease-in",
  position: "relative",
  ":hover": {
    cursor: "pointer",
  },

  fontSize: [null, null, "1.5rem", "1.12rem"],
};

const links = {
  styledLink: styledLink,
  styledHeaderLink: {
    ...styledLink,

    "&::after": {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "0%",
      content: '""',
      color: "transparent",
      background: "headerlink",
      height: "1px",
      transition: "all 0.4s ease-in",

    },
    "&.active": {
      "&::after": {
        color: "headerlink",
        width: "100%",
      }
    },
    "&:hover": {
      color: "headerlinkhover",
      "::after": {
        width: "100%",
      },
    },

  },
  styledHeaderDropdownLink: {
    ...styledLink,
    "& .submenu1": {
      listStyle: "none",
      m: 0,
      p: "3px",
      backgroundColor: "submenu",
      visibility: ["visible", "visible", "visible", 'hidden'],
      opacity: [1, 1, 1, 0],
      display: "block",
      minWidth: "8rem",
      position: ["relative", "relative", "relative", 'absolute'],
      transition: "all 400ms ease-in-out",
      top: 0,
      zIndex: 1,
    },
    ":hover > div > .submenu1, :focus-within > .submenu1 ": {
      visibility: "visible",
      opacity: "1",
    },
  }
};
const forms = {
  label: {
    fontSize: 1,
    fontWeight: "bold",
  },
  input: {
    borderColor: "borderscolor",
    mb: 3,
    "&:focus": {
      borderColor: "primary",
      boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
    },
  },
  select: {
    borderColor: "borderscolor",
    "&:focus": {
      borderColor: "primary",
      boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
    },
  },
  textarea: {
    borderColor: "borderscolor",
    mb: 3,
    "&:focus": {
      borderColor: "primary",
      boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
      outline: "none",
    },
  },
  slider: {
    bg: "muted",
  },
};

const flexes = {
  flexColCenterHight100vh: {
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  },
  flexColLeftHight100vh: {
    flexDirection: "column",
    alignItems: "start",
    height: "100vh",
  },

  flexColCenterMaxHight100PercentFlexStart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxHeight: "100%",
  },
};
const boxes = {
  jumbotron: {
    px: 2,
    py: 1,
    mb: 2,
    bg: "primary",
    borderRadius: "0.3rem",
  },
};
const styles = {
  root: {
    overflowY: "scroll",

    fontFamily: `body`,
    lineHeight: `body`,
    fontWeight: `body`,
    a: {
      color: `primary`,
      textDecoration: `none`,
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        color: `primaryhover`,
      },
    },
  },
  a: {
    color: `primary`,
    textDecoration: `none`,
    ":hover": {
      textDecoration: `underline`,
    },
  },
  h1: {
    mt: 2,
    fontSize: ["1.5rem", "1.875rem", "2.25rem", "2.5rem"],
  },
  h2: {
    mt: 2,
    fontSize: ["1.5rem", "1.875rem", "2.25rem", "2.5rem"],
  },
  h3: {
    mt: 3,
    mb: [0, 1, 2, 3],
    fontSize: ["1.25rem", "1.5rem", "1.875rem",],
  },
  h4: {
    fontSize: [1, 2, 3],
  },
  h5: {
    fontSize: [1, 2, 3],
  },
  h6: {
    fontSize: [1, 2, 3],
  },
  code: {},
  pre: {},
  hr: {
    bg: `muted`,
    border: 0,
    height: `1px`,
    m: 3,
  },
};
const myTheme = {
  useColorSchemeMediaQuery: false,
  initialColorModeName: `light`,
  useCustomProperties: true,
  useLocalStorage: true,
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  sizes,
  shadows,
  space,
  radii,
  zIndices,
  images,
  buttons,
  links,
  forms,
  flexes,
  boxes,
  styles,
};

export default merge({}, myTheme);
